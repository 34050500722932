import React from "react";
import { wrapper } from "store";
import "styles/above-the-fold.scss";
import "styles/main.scss";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import { AppProps } from "next/app";
import { Ubuntu } from "next/font/google";
import { Provider } from "react-redux";
import { clientConfig } from "helpers/rollbar";

const ubuntu = Ubuntu({
  weight: ["300", "400", "500"],
  subsets: ["latin"],
});

const App: React.FC<AppProps> = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;

  return (
    <RollbarProvider config={clientConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <main className={ubuntu.className}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...pageProps} />
          </main>
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default App;
